module.exports = {
  HOME: 'Homepage',
  WALLET: 'Wallet',
  SERVICE: 'Service',
  MY: 'My',
  APPLYMONEY: 'Apply money',
  AWAITJIE: 'Await refund money',
  UNIT: '₱',
  BORRORWMONEYTIME: 'Loan term',
  EVERYISSUE: 'Repayment each period',
  DAYRATE: 'Monthly interest rate',
  MOTHLYINTEREST: 'Monthly interest',
  CONSENT: 'Consent',
  AGREEMENTENT: 'Agreement',
  ATONECBORROWMONEY: 'Immediately loan',
  CONSENTPROTOCOL: 'Please consent and check agreement',
  LOANAPPLICATION: 'Loan application',
  CONSENTBORROWMESSAGE: 'Consent loan message',
  BORRORWMONEY: 'Loan amount',
  ACCOUNTBANK: 'Deposit received bank',
  PAYEEBOX: 'Deposit received account',
  SUBMITBORROWTEXT: 'Submit lending order',
  PLEACHECKPROTOCOL: 'Please check the agreement',
  MONTHS: 'Months',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  SUBMIT: 'Submit',
  CHECKUSERNMAE: 'Username field is required',
  CHECKPASSWORD: 'Password field is required',
  REGISTER: 'Register',
  FORGET: 'Forgot Password',
  NOTCHINA: 'Chinese invalid',
  LIJIRECHARGE: 'Deposit now',
  CANCEL: 'Cancel',
  RECHARGEMONEY: 'Deposit amount',
  WITHDRAWDEPOSIT: 'Withdraw amount',
  WITHDRAWDEPOSITPASSWORD: 'Withdraw password',
  INPUTWITHDRAWDEPOSITPASSWORD: 'Please enter withdraw password',
  WITHDRAWDEPOSITMONEYNULL: 'Please enter withdraw amount',
  WITHDRAWDEPOSITNULL: 'Please enter withdraw password',
  MYDATA: 'My data',
  MYBORROW: 'My loan',
  MYREFUND: 'My refund',
  OPENVIP: 'VIP activate',
  CHANGEPASSWORD: 'Change password',
  LOGOUT: 'Log out',
  MSUTDATAFULL: 'Please fill in the required information for applying loan',
  IDENTITYDATA: 'Identification',
  UNDERSTANDBASICINFORMATION: 'Let us know your basic information',
  DATAMESSAGE: 'Data Information',
  RECEIPTBANKCARD: 'Bank card for receiving',
  PHONENUMVERIFY: 'Phone number vertification',
  VERIFYSELFPHONENUM: 'Please verify your mobile number',
  INCREASETHEPASSRATE: 'Below are optional information to increase quota and verify pass rate',
  FURTHERINFORMATION: 'Additional  information',
  REVIEWTHEPASSRATE: 'To increase your verify pass rate',
  FILLED: 'Filled',
  FILLINTRUEINFORMATION: 'Real and valid information is required for approval',
  NAME: 'Name',
  IDCARDNUMBER: 'ID number',
  UPLOADIDPROTO: 'Upload ID photo',
  IDPOSITIVEPROTO: 'ID card front photo',
  IDONTHEBRECKPROTO: 'ID card back photo',
  HANDTAKEIDPROTO: 'Hand holding ID card photo',
  ENTITYNAME: 'Company name',
  POSITION: 'Position',
  ENTITYPHONE: 'Contact phone',
  WORKAGE: 'Job tenure',
  CURRENTRESIDENCEDETAILS: 'Current residence address',
  CONTACTOFIMMEDIATEFAMILY: 'nDirect blood relative',
  CONTACTNAME: 'Contact name',
  CONTACTPHONE: 'Phone Number',
  CONTACTRELATION: 'Relationship',
  OTHER: 'Others',
  TAKECARDNAME: 'Name',
  TAKEID: 'Card Holder’s ID',
  BANKNAME: 'Name Of The Bank',
  BANKCRAD: 'Bank Account Number',
  BANKCRADSELF: 'Friendly reminder: the provided bank card must be a debit card registered under your name',
  INPUTBANKCARDNUMBER: 'Enter Bank Account Number',
  SAVEDATA: 'Add data',
  BORRORWDETAIL: 'Loan detail',
  BORRORWPLAN: 'Loan progress',
  SUBMITSUCCEED: 'Submit succeed',
  ARRIVEMONEYSUCCEED: 'Arrived',
  ORDERFORMNUM: 'Order number',
  REFUNDSTATE: 'Refund statement',
  NEEDNOTBORRORW: 'No repayment needed!',
  CURRENTMONTHNEEDNOTBORRORW: 'No replayment needed for current month',
  NOTE: 'SMS message verification',
  NOTENUM: 'SMS message verification code',
  NWESPASSWORD: 'New password',
  INPUTPHONE: 'Enter phone number',
  INPUTNOTE: 'Enter SMS message verification code',
  INPUTNEWSPASSWORD: 'Enter a 6-16 bits password',
  CONFIRM: 'Confirm',
  PLEAINPUTNAME: 'Enter name',
  PLEAINPUTID: 'Enter ID number',
  PLEAENTITYNAME: 'Enter company name',
  PLEAPOSITION: 'Enter position',
  PLEAENTITYPHONE: 'Enter work phone',
  PLAEAWORKAGE: 'Enter job tenure',
  PLEACURRENTRESIDENCEDETAILS: 'Enter current address in detail',
  PLEACONTACTRELATION: 'E.g., parents or children',
  BORRORSERIALBUNBER: 'Case number of loan',
  TOTAILMONEY: 'Total amount',
  RECHARGEMONEYNONTNULL: 'Deposit amount cannot be empty',
  RECHARGE: 'Deposit detail',
  UPLOADVOUCHER: 'Upload certificate',
  UPLOADONEPROTO: 'Upload a photo',
  USERREGISTER: 'User registration',
  FORGETPASSWORD: 'Forgot password',
  NOTNULL: 'Cannot be empty',
  LENGTH10: 'Length must not be less than 10 bits',
  REGISTEROK: 'Registration success',
  LOGINOK: 'Successfully logged in',
  AWAIT: 'Await',
  THEACCOUNTBALANCE: 'Account balance',
  UNFILLED: 'Unfilled',
  UNITADDRESS: 'Company address',
  INPUTUNITADDRESS: 'Enter company address',
  OKBORRORW: 'Loan approved',
  PLEAMESSAGECOMPLETE: 'Please complete the information',
  WORKCERTIFICATE: 'Certificate of employment',
  INCOMEPROOF: 'Income certificate',
  NOBRORROW: 'No loan',
  MYWITHDRAWDEPOSIT: 'My withdraw',
  NOMYWITHDRAWDEPOSIT: 'No withdraw',
  MYWITHDRAWDEPOSITIME: 'Withdraw time',
  MYWITHDRAWDEPOSITMONEY: 'Withdrawal Amount',
  MYWITHDRAWDEPOSITDETAILS: 'Withdraw detail',
  VIP: 'Member',
  LINK: 'Link',
  STUTA: 'Status',
  MESSAGE: 'Message',
  MYWITHDRAWDEPOSILONG: 'Withdraw progess',
  LOADAPP: 'Download APP',
  LIJITIXIAN: 'Withdraw now',
  TIXIANID: 'Withdraw number',
  WARMPROMPT: 'Friendly reminder',
  CONTACTME: 'Contact me',
  QUESTION: 'TQ&A',
  TOFAILURE: 'Failed to arrive',
  WENXINGTISHI: 'Friendly reminder: In progress. Sign up to expedite the review process',
  OPENVVIP: 'Sign up',
  SEND_SMS: 'Send SMS message code',
  CUSTOMERSERVICECENTER: 'Customer service center',
  BORROWINGTIME: 'Loan time',
  IMGNOT: 'Image cannot be empty',
  PLEALOGIN: 'Please login',
  Pleasereviewtheprotocolcarefully: 'Please go over the protocol',
  Lookatthecontract: 'Check the contract',
  FILLINALL: 'Please fill in all fields',
  WDNUMBER: 'Withdraw code',
  PWDWDNUMBER: 'Enter withdraw code',
  EDITTHEAMOUNT: 'Edit the amount',
  THENEWPASSWORD: 'Please enter new password',
  MODIFYTHESUCCESS: 'Modify succeeded',
  MODIFYTHEFAILURE: 'Modify failed',
  CONTACTCUSTOMERSERVICE: 'Contact customer service',
  TIX: 'Withdraw',
  CHECKPASSWORDRe: 'Confirm password',
  TWICE: 'The two passwords do not match',
  HT: 'Contract',
  Contart_Number: 'Contact Number'
}
