export const getItem = name => {
  const data = window.localStorage.getItem(name)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}
export const setItem = (name, value) => {
  if (value === 'object') {
    console.log({} === 'object')
    value = JSON.stringify(value)
  }
  window.localStorage.setItem(name, value)
}
export const romoveItem = name => {
  window.localStorage.removeItem(name)
}
