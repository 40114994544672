import Vue from 'vue'
import VueRouter from 'vue-router'
import { getItem } from '@/utils/storage'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    component: () => import('@/views/Layout/'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home/index')
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('@/views/My/index')
      },
      {
        path: '/wallet',
        component: () => import('@/views/Wallet/index'),
        children: [
          {
            path: '/wallet/loansComponent',
            name: 'loansComponent',
            component: () => import('@/views/Wallet/component/loansComponent')
          },
          {
            path: '/wallet/withdrawdeposit',
            name: 'withdrawdeposit',
            component: () => import('@/views/Wallet/component/withdrawdeposit')
          }
        ]
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('@/views/Service/index')
      }
    ]
  },
  // home下的Page
  {
    path: '/home/loanapplication',
    name: 'loanapplication',
    component: () => import('@/views/Home/loanapplication')
  },
  // login下的page
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Login/register')
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/views/Login/forget')
  },
  // my下的page
  {
    path: '/my/mydata',
    name: 'mydata',
    component: () => import('@/views/My/mydata')
  },
  // /my/mydata下的页面
  {
    path: '/my/mydata/identitydata',
    name: 'identitydata',
    component: () => import('@/views/My/identitydata')
  },
  {
    path: '/my/mydata/openlevel',
    name: 'openlevel',
    component: () => import('@/views/My/openlevel')
  },
  {
    path: '/my/mydata/datamessage',
    name: 'datamessage',
    component: () => import('@/views/My/datamessage')
  },
  {
    path: '/my/mydata/receiptBankCard',
    name: 'receiptBankCard',
    component: () => import('@/views/My/receiptBankCard')
  },
  {
    path: '/my/mydata/furtherinformation',
    name: 'furtherinformation',
    component: () => import('@/views/My/furtherinformation')
  },
  {
    path: '/my/myborrow',
    name: 'myborrow',
    component: () => import('@/views/My/myborrow')
  },
  {
    path: '/my/myborrow/borrowdetail',
    name: 'borrowdetail',
    component: () => import('@/views/My/borrowdetail')
  },
  {
    path: '/my/myrefund',
    name: 'myrefund',
    component: () => import('@/views/My/myrefund')
  },
  {
    path: '/my/changepassword',
    name: 'changepassword',
    component: () => import('@/views/My/changepassword')
  },
  {
    path: '/my/ht',
    name: 'ht',
    component: () => import('@/views/My/ht')
  },
  // 钱包下的页面
  {
    path: '/wallet/recharge',
    name: 'recharge',
    component: () => import('@/views/Wallet/recharge')
  },
  // 我的提现
  {
    path: '/my/wywithdrwdeposit',
    name: 'wywithdrwdeposit',
    component: () => import('@/views/My/wywithdrwdeposit')
  },
  {
    path: '/my/wywithdrwdepositdetail',
    name: 'wywithdrwdepositdetail',
    component: () => import('@/views/My/wywithdrwdepositdetail')
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/APP/index')
  },
  {
    path: '/my/apassword',
    name: 'apassword',
    component: () => import('@/views/My/apassword')
  },
  // 电子签名
  {
    path: '/signature',
    name: 'signature',
    component: () => import('@/views/My/signature')
  },
  // 合同
  {
    path: '/hetong',
    name: 'hetong',
    component: () => import('@/views/My/hetong')
  },
  // 支付
  {
    path: '/gcash',
    name: 'Gcash',
    component: () => import('@/views/My/Gcash')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to.path)
  // console.log(getItem('token'))
  if (getItem('b_token')) {
    next()
  } else {
    if (to.path === '/register' || to.path === '/' || to.path === '/login' || to.path === '/forget' || to.path === '/home') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
