module.exports = {
  HOME: 'trang chủ',
  WALLET: 'ví tiền',
  SERVICE: 'dịch vụ',
  MY: 'của tôi',
  APPLYMONEY: 'đơn số tiền(vnd)',
  AWAITJIE: 'Số tiền phảitrả(vnd)',
  UNIT: 'vnd',
  BORRORWMONEYTIME: 'Thời gian cho vay',
  EVERYISSUE: 'Trả dần từng đợt',
  DAYRATE: 'Lãi suất hàng tháng',
  MOTHLYINTEREST: 'Tiền lãi hàng tháng',
  CONSENT: 'đồng ý',
  AGREEMENTENT: '《Hiệp định vay vốn》',
  ATONECBORROWMONEY: 'Vay ngay',
  CONSENTPROTOCOL: 'Vui lòng đồng ý và kiểm tra thỏa thuận',
  LOANAPPLICATION: 'Đăng ký khoản vay',
  CONSENTBORROWMESSAGE: 'Xác nhận thông tin khoản vay',
  BORRORWMONEY: 'Số tiền vay',
  ACCOUNTBANK: 'đến tài khoản ngân hàng',
  PAYEEBOX: 'tài khoản Nhận ',
  SUBMITBORROWTEXT: 'Gửi hóa đơn',
  PLEACHECKPROTOCOL: 'Vui lòng kiểm tra thỏa thuận này',
  MONTHS: 'Tháng',
  USERNAME: 'tên tài khoản',
  PASSWORD: 'mật khẩu',
  SUBMIT: 'Gửi đi',
  CHECKUSERNMAE: 'Tên người dùng không được để trống',
  CHECKPASSWORD: 'mật khẩu không được để trống',
  REGISTER: 'đăng ký',
  FORGET: 'Quên mật khẩu',
  NOTCHINA: 'Không thể sử dụng tiếng Trung',
  LIJIRECHARGE: 'Nạp tiền ngay bây giờ',
  CANCEL: 'hủy bỏ',
  RECHARGEMONEY: 'Số tiền nạp',
  WITHDRAWDEPOSIT: 'Số tiền rút',
  WITHDRAWDEPOSITPASSWORD: 'Mật khẩu rút tiền',
  INPUTWITHDRAWDEPOSITPASSWORD: 'Vui lòng nhập mật khẩu rút tiền',
  WITHDRAWDEPOSITMONEYNULL: 'Số tiền rút không được để trống',
  WITHDRAWDEPOSITNULL: 'Mật khẩu rút tiền không được để trống',
  MYDATA: 'Thông tin của tôi',
  MYBORROW: 'Khoản vay của tôi',
  MYREFUND: 'trả nợ của tôi',
  OPENVIP: 'Mở VIP',
  CHANGEPASSWORD: 'đổi mật khẩu',
  LOGOUT: 'đăng xuất',
  MSUTDATAFULL: 'Sau đây là các thông tin bắt buộc phải điền để đăng ký vay',
  IDENTITYDATA: 'Thông tin nhận dạng',
  UNDERSTANDBASICINFORMATION: 'Hãy cho chúng tôi biết tình huống cơ bản của bạn',
  DATAMESSAGE: 'Thông tin',
  RECEIPTBANKCARD: 'thẻ ngân hàng Nhận',
  PHONENUMVERIFY: 'Xác minh số điện thoại di động',
  VERIFYSELFPHONENUM: 'Xác minh số điện thoại di động của bạn',
  INCREASETHEPASSRATE: 'Sau đây là thông tin bổ sung tùy chọn để tăng hạn ngạch và xác suất vượt qua bài đánh giá',
  FURTHERINFORMATION: 'tài liệu bổ sung',
  REVIEWTHEPASSRATE: 'Tăng cơ hội vượt qua bài đánh giá của bạn',
  FILLED: '已填写',
  FILLINTRUEINFORMATION: 'Điền thông tin thật và hợp lệ, bài đánh giá sẽ được thông qua~',
  NAME: 'tên đầu tiên',
  IDCARDNUMBER: 'số cmnd ',
  UPLOADIDPROTO: 'tải lên ảnh cmnd ',
  IDPOSITIVEPROTO: 'Ảnh mặt trước của CMND',
  IDONTHEBRECKPROTO: 'Ảnh mặt sau của CMND',
  HANDTAKEIDPROTO: 'Cầm ảnh chứng minh thư trong tay',
  ENTITYNAME: 'Tên công ty',
  POSITION: 'Chức vụ',
  ENTITYPHONE: 'điện thoại cơ quan',
  WORKAGE: 'Thời hạn phục vụ',
  CURRENTRESIDENCEDETAILS: 'Địa chỉ đầy đủ của nơi ở hiện tại',
  CONTACTOFIMMEDIATEFAMILY: 'người Liên hệ ngay ',
  CONTACTNAME: 'Tên',
  CONTACTPHONE: 'số điện thoại',
  CONTACTRELATION: 'mối quan hệ',
  OTHER: 'Các liên hệ khác',
  TAKECARDNAME: 'Tên của chủ thẻ',
  TAKEID: 'CMND của chủ thẻ',
  BANKNAME: 'tài khoản ngân hàng',
  BANKCRAD: 'Số thẻ ngân hàng',
  BANKCRADSELF: 'Lời nhắc：Thẻ ngân hàng bạn điền phải là thẻ ghi nợ (debit card) đứng tên bạn。',
  INPUTBANKCARDNUMBER: 'Vui lòng nhập số thẻ ngân hàng',
  SAVEDATA: 'Thêm thông tin',
  BORRORWDETAIL: 'Chi tiết khoản vay',
  BORRORWPLAN: 'Tiến độ cho vay',
  SUBMITSUCCEED: 'gửi thành công',
  ARRIVEMONEYSUCCEED: 'Đã đến thành công',
  ORDERFORMNUM: 'Số đơn ',
  REFUNDSTATE: 'Hướng dẫn trả nợ',
  NEEDNOTBORRORW: 'Không trả nợ！',
  CURRENTMONTHNEEDNOTBORRORW: 'Bạn không có hóa đơn nào để trả nợ trong tháng này',
  NOTE: 'Bạn không có hóa đơn nào để trả lại trong tháng nàyBạn không có hóa đơn nào để trả lại trong tháng này',
  NOTENUM: 'Mã xác minh SMS',
  NWESPASSWORD: 'mật khẩu mới',
  INPUTPHONE: 'Vui lòng nhập số điện thoại',
  INPUTNOTE: 'Vui lòng nhập mã xác minh SMS',
  INPUTNEWSPASSWORD: 'Vui lòng đặt mật khẩu 6-16 chữ số',
  CONFIRM: 'mục đích',
  PLEAINPUTNAME: 'Vui lòng nhập tên của bạn',
  PLEAINPUTID: 'Vui lòng nhập số cmnd',
  PLEAENTITYNAME: 'Vui lòng nhập tên của đơn vị',
  PLEAPOSITION: 'Vui lòng nhập một vị trí',
  PLEAENTITYPHONE: 'Vui lòng nhập số điện thoại cơ quan của bạn',
  PLAEAWORKAGE: 'Vui lòng nhập năm công việc',
  PLEACURRENTRESIDENCEDETAILS: 'Vui lòng nhập địa chỉ chi tiết của nơi ở hiện tại của bạn',
  PLEACONTACTRELATION: 'Ví dụ: cha mẹ và con cái',
  BORRORSERIALBUNBER: 'biển số khoản vay',
  TOTAILMONEY: 'tổng số tiền',
  RECHARGEMONEYNONTNULL: 'Số tiền nạp không được để trống',
  RECHARGE: 'Chi tiết nạp tiền',
  UPLOADVOUCHER: 'tải lên chứng chỉ',
  UPLOADONEPROTO: 'Tải lên một hình ảnh',
  USERREGISTER: 'Đăng ký người dùng',
  FORGETPASSWORD: 'Lấy lại mật khẩu',
  NOTNULL: 'Không được để trống',
  LENGTH10: 'Chiều dài không được nhỏ hơn 10',
  REGISTEROK: 'đăng ký thành công',
  LOGINOK: ' đăng nhập thành công',
  AWAIT: 'Đang chờ ',
  THEACCOUNTBALANCE: 'Số dư tài khoản',
  UNFILLED: 'chưa điền vào ',
  UNITADDRESS: 'Địa chỉ đơn vị ',
  INPUTUNITADDRESS: 'Vui lòng nhập địa chỉ đơn vị của bạn',
  OKBORRORW: 'Vay thành công',
  PLEAMESSAGECOMPLETE: 'Vui lòng ghi đầy đủ thông tin',
  WORKCERTIFICATE: 'Bằng chứng về việc làm',
  INCOMEPROOF: 'Chứng minh thu nhập',
  NOBRORROW: 'chưa vay',
  MYWITHDRAWDEPOSIT: 'Rút tiền của tôi',
  NOMYWITHDRAWDEPOSIT: 'Không có thông tin rút tiền',
  MYWITHDRAWDEPOSITIME: 'Thời gian rút tiền',
  MYWITHDRAWDEPOSITMONEY: 'Số tiền rút',
  MYWITHDRAWDEPOSITDETAILS: 'Chi tiết rút tiền',
  VIP: 'thành viên',
  LINK: 'liên kết',
  STUTA: 'trạng thái',
  MESSAGE: 'Tin tức',
  MYWITHDRAWDEPOSILONG: 'Tiến độ rút tiền',
  LOADAPP: 'Tải ứng dụng',
  LIJITIXIAN: 'Rút tiền ngay bây giờ',
  TIXIANID: 'số đơn rút tiền',
  WARMPROMPT: 'Lời nhắc',
  CONTACTME: 'liên hệ tôi',
  QUESTION: 'Trả lời câu hỏi',
  TOFAILURE: 'gửi đi thất bại',
  WENXINGTISHI: 'nhắc nhở，đang kiểm tra ，Mở đánh giá cấp tốc thành viên',
  OPENVVIP: 'tham gia thành viên',
  SEND_SMS: 'Gửi mã xác minh',
  CUSTOMERSERVICECENTER: 'Dịch vụ khách hàng',
  BORROWINGTIME: 'Thời gian cho vay',
  IMGNOT: 'Hình ảnh không được để trống',
  PLEALOGIN: 'vui lòng đăng nhập',
  Pleasereviewtheprotocolcarefully: 'Vui lòng xem kỹ thỏa thuận',
  Lookatthecontract: 'Xem hợp đồng',
  FILLINALL: 'Vui lòng điền vào tất cả',
  WDNUMBER: 'Mã rút tiền',
  PWDWDNUMBER: 'Vui lòng nhập mã rút tiền',
  EDITTHEAMOUNT: 'Chỉnh sửa số tiền',
  THENEWPASSWORD: 'Vui lòng nhập mật khẩu mới',
  MODIFYTHESUCCESS: 'Đã sửa đổi thành công',
  MODIFYTHEFAILURE: 'không thể chỉnh sửa',
  CONTACTCUSTOMERSERVICE: 'liên hệ với dịch vụ khách hàng',
  TIX: 'rút tiền ',
  CHECKPASSWORDRe: 'Xác nhận mật khẩu',
  TWICE: 'Hai mật khẩu không khớp',
  HT: 'hợp đồng'
}
